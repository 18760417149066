.table-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.prize-table {
  border-collapse: collapse;
  width: 90%;
  margin: auto;
  background-color: #2c2c2c; /* Dark background */
  color: white; /* White text */
  text-align: left;
}

.prize-table th,
.prize-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.prize-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #4c4c4c; /* Slightly lighter background for header */
  color: white;
  text-align: center; /* Center text in headers */
}
